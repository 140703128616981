import React, { useContext } from 'react'
import { faDownload, faPlus, faShoppingBasket } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../components/Button/Button'
import CategoryHeader from '../../components/CategoryHeader'
import MessagesWidget from '../../components/MessagesWidget/MessagesWidget'
import Notifications from '../../components/Notifications/Notifications'
import OrdersWidget from '../../components/OrdersWidget/OrdersWidget'
import { NavLink, useNavigate } from 'react-router-dom'
import { TibiContext } from '../../context/ContextProvider'
import DashboardNotification from '../../components/DashboardNotification/DashboardNotification'
import DeliveriesWidget from '../../components/DeliveriesWidget/DeliveriesWidget'
import { useDeliveries } from '../../hooks/useDeliveries'

const DashboardIndex: React.FunctionComponent = () => {
    // Auth hook for components
    const { value }: any = useContext(TibiContext)
    const user = value?.user
    const navigate = useNavigate()
    const { getNumNewDeliveries } = useDeliveries()

    const deliveriesWidgetSize = 4

    return (
        <div className='w-full h-full flex flex-col space-y-6'>
            <div>
                <h2 className='text-lg sm:text-2xl font-semibold text-black'>
                    Welkom, <span className='capitalize'>{user?.name}</span>
                </h2>
            </div>

            {getNumNewDeliveries() > 0 && (
                <DashboardNotification icon={faDownload}>
                    <span className='text-tibi-text text-sm'>
                        Er zijn <span className='text-tibi-primary font-bold'>{getNumNewDeliveries()}</span> nieuwe
                        opleveringen beschikbaar, download ze&nbsp;
                        <button
                            type='button'
                            className='underline hover:no-underline font-semibold text-tibi-text'
                            onClick={() => navigate('/dashboard/deliveries')}
                        >
                            hier
                        </button>
                        .
                    </span>
                </DashboardNotification>
            )}

            <div className='flex justify-between items-center my-2 flex-wrap gap-4'>
                <CategoryHeader icon={faShoppingBasket} title='Lopende opdrachten' />
                <Button>
                    <NavLink to={'/dashboard/new-order'}>
                        <FontAwesomeIcon icon={faPlus} className='mr-2' />
                        Nieuwe opdracht
                    </NavLink>
                </Button>
            </div>
            <OrdersWidget limit={4} />
            <div className='flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row lg:gap-x-4'>
                <MessagesWidget />
                <Notifications />
            </div>

            <CategoryHeader icon={faDownload} title='Laatste opleveringen' />
            <DeliveriesWidget size={deliveriesWidgetSize} />
        </div>
    )
}

export default DashboardIndex
