import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DASHBOARD_ROUTES } from '../../helpers/constants'
import { useDeliveries } from '../../hooks/useDeliveries'

const AsideNav: React.FunctionComponent<any> = () => {
    const { getNumNewDeliveries } = useDeliveries()

    return (
        <nav className='w-[230px] shadow-sm flex-shrink-0 border border-tibi-fadedPrimary border-opacity-50 rounded-xl hidden sm:flex flex-col mr-4 md:mr-6 h-auto mb-auto bg-white overflow-hidden pb-24'>
            {/* SIDE CONTENT */}
            {/* LOOP OVER NAVIGATION HERE */}
            <ul>
                {DASHBOARD_ROUTES.map((route, i) => {
                    return (
                        <li key={i}>
                            <NavLink
                                to={route.to}
                                end={route.to === ''}
                                className={({ isActive }) =>
                                    isActive
                                        ? 'text-black relative activeNavLink'
                                        : 'relative text-tibi-text hover:text-black'
                                }
                            >
                                <div className='flex flex-row items-center pl-4 relative'>
                                    {route.icon && (
                                        <span className='mr-3 w-5 flex items-center justify-center'>
                                            <FontAwesomeIcon icon={route.icon} />
                                        </span>
                                    )}
                                    <span
                                        className={`${
                                            i === DASHBOARD_ROUTES.length - 1 ? 'border-none' : 'border-b'
                                        } flex w-full py-3 border-tibi-fadedPrimary font-medium justify-between border-opacity-40 gap-x-2 items-center`}
                                    >
                                        {route.displayName}

                                        {route.displayName === 'Opleveringen' && getNumNewDeliveries() > 0 && (
                                            <div className='rounded-full w-5 h-5 mr-3 flex items-center justify-center bg-tibi-primary text-white text-xs font-bold'>
                                                {getNumNewDeliveries()}
                                            </div>
                                        )}
                                    </span>
                                </div>
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default AsideNav
