import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle as faPlusCircleSolid } from '@fortawesome/pro-light-svg-icons'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { formatCurrencyValue } from '../../helpers/utils'
import { OrderLine, Product, ProductOption } from '../../types'

interface ProductItemProps {
    product: any
    showHighlight?: boolean
    showOptions?: boolean
    productInBasket?: undefined | Product
    disabled?: boolean
    size?: 'small'
    addProductToBasket?: (product: any) => void // Replace 'any' with the actual type of the product
    removeProductFromBasket?: (product: any) => void // Replace 'any' with the actual type of the product
}

const ProductItem: React.FC<ProductItemProps> = ({
    product,
    showHighlight = true,
    showOptions = true,
    productInBasket,
    disabled = false,
    size = undefined,
    addProductToBasket,
    removeProductFromBasket
}) => {
    const [over, setOver] = useState<boolean>(false)

    const selectedProductOptions: ProductOption[] | OrderLine[] | undefined | false =
        product.options ||
        (productInBasket &&
            productInBasket.availableOptions &&
            productInBasket.availableOptions.length > 0 &&
            productInBasket?.availableOptions.filter((availableOption: any) => availableOption._isAdded))

    return (
        <div
            className={`flex flex-grow bg-tibi-green  ${
                productInBasket && showHighlight ? 'bg-opacity-10' : 'bg-opacity-0'
            } px-4 border-b border-b-tibi-fadedPrimary border-opacity-50 last:border-b-0 justify-between items-center ${
                size === 'small' ? 'py-1' : 'py-3'
            } ${disabled ? 'opacity-50 pointer-events-none' : ''}`}
        >
            <div className='flex items-center'>
                <IconWithBackground size={size} iconName={product.icon} />
                <div className='ml-4'>
                    <h2 className={'font-semibold text-base mb-0 ' + (size === 'small' ? ' text-sm' : '')}>
                        {product.name}
                    </h2>
                    {product.description && (
                        <span
                            className='text-xs text-gray-500'
                            dangerouslySetInnerHTML={{ __html: product.description }}
                        ></span>
                    )}
                    {showOptions &&
                        selectedProductOptions &&
                        selectedProductOptions?.map((availableOption: any, i: number) => {
                            return (
                                <div key={i} className='text-xs text-gray-500'>
                                    <span>+ {availableOption.name}</span>
                                </div>
                            )
                        })}
                    {disabled && (
                        <div className='text-xs text-tibi-primary'>
                            <span>Al geselecteerd</span>
                        </div>
                    )}
                </div>
            </div>
            <div className='space-x-4 flex items-center'>
                {product.price && (
                    <div className='px-4 text-sm'>
                        {formatCurrencyValue(product.price)}
                        {showOptions &&
                            selectedProductOptions &&
                            selectedProductOptions?.map((availableOption: any) => {
                                return (
                                    <div key={availableOption.id} className='text-xs text-gray-500'>
                                        <span>{formatCurrencyValue(availableOption.price)}</span>
                                    </div>
                                )
                            })}
                    </div>
                )}
                {/* <button className='rounded-lg h-10 w-10 flex items-center justify-center border'>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </button> */}
                {removeProductFromBasket && addProductToBasket && (
                    <button
                        className='cursor-pointer'
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                        onClick={() =>
                            productInBasket ? removeProductFromBasket(product) : addProductToBasket(product)
                        }
                    >
                        <FontAwesomeIcon
                            icon={productInBasket ? faCheckCircle : over ? faPlusCircleSolid : faPlusCircle}
                            className='text-[#2EB5AD] text-3xl'
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default ProductItem
