import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import { OrderDelivery } from '../../types'
import DeliveryItem from '../../pages/Deliveries/DeliveryItem'
import { useDeliveries } from '../../hooks/useDeliveries'

interface DeliveriesWidgetProps {
    size?: number
}

const DeliveriesWidget = ({ size = 4 }: DeliveriesWidgetProps) => {
    const navigate = useNavigate()
    const { deliveryList, getDeliveries } = useDeliveries()

    const deliveries = deliveryList.slice(0, size)

    return (
        <div className='bg-white shadow-sm border-tibi-fadedPrimary border-opacity-50 border rounded-xl h-auto pt-2'>
            <div className='flex flex-col'>
                {deliveries.length === 0 ? (
                    <div className='text-center text-gray-400 py-8'>Geen opleveringen</div>
                ) : (
                    deliveries.map((delivery: OrderDelivery) => {
                        return <DeliveryItem key={delivery.id} data={delivery} onDownload={getDeliveries} />
                    })
                )}
            </div>

            {deliveries.length > 0 && (
                <div className='px-4 py-4'>
                    <Button className='bg-white text-tibi-text border' onClick={() => navigate('deliveries')}>
                        <span>Alle opleveringen</span>
                        <FontAwesomeIcon icon={faArrowRight} className='ml-2' />
                    </Button>
                </div>
            )}
        </div>
    )
}

export default DeliveriesWidget
