import { DASHBOARD_ROUTES } from '../../helpers/constants'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import tibiLogo from '../../images/TibiLogo.svg'
import { useDeliveries } from '../../hooks/useDeliveries'

interface AsideNaveMobileProps {
    openSidebarNav: boolean
    setOpenSidebarNav: (value: boolean) => void
}

const AsideNaveMobile = ({ openSidebarNav, setOpenSidebarNav }: AsideNaveMobileProps) => {
    const navigate = useNavigate()
    const { getNumNewDeliveries } = useDeliveries()

    const menuClassName: string = openSidebarNav ? 'translate-x-0' : '-translate-x-full'
    const overlayClassName: string = openSidebarNav ? 'opacity-20 pointer-events-auto' : 'opacity-0 pointer-events-none'

    return (
        <>
            <nav
                className={`fixed z-50 transition-transform duration-300 ease-in-out top-0 left-0 h-screen w-[300px] border-r border-tibi-fadedPrimary sm:hidden bg-white space-y-4 ${menuClassName}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className='flex p-4 items-center gap-x-4 border-b border-tibi-fadedPrimary border-opacity-60 pb-4 relative'>
                    <button
                        type='button'
                        onClick={(e) => {
                            e.preventDefault()
                            navigate('/dashboard')
                            setOpenSidebarNav(false)
                        }}
                    >
                        <img className='h-[45px] object-cover' src={tibiLogo} alt='TibiMedia logo' />
                    </button>
                    <button
                        type='button'
                        className='text-tibi-mainHeading absolute text-xl right-0 top-0 p-4'
                        onClick={() => setOpenSidebarNav(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <ul>
                    {DASHBOARD_ROUTES.map((route, i) => {
                        return (
                            <li key={i}>
                                <NavLink
                                    to={route.to}
                                    end={route.to === ''}
                                    className={({ isActive }) =>
                                        isActive ? 'text-black relative activeNavLink' : 'relative'
                                    }
                                    onClick={() => setOpenSidebarNav(false)}
                                >
                                    <div className='flex flex-row items-center pl-4 relative'>
                                        {route.icon && (
                                            <span className='mr-3 w-5 flex items-center justify-center'>
                                                <FontAwesomeIcon icon={route.icon} />
                                            </span>
                                        )}
                                        <span
                                            className={`${
                                                i === DASHBOARD_ROUTES.length - 1 ? 'border-none' : 'border-b'
                                            } flex w-full py-3 border-tibi-fadedPrimary border-opacity-50 items-center gap-x-2`}
                                        >
                                            {route.displayName}

                                            {route.displayName === 'Opleveringen' && getNumNewDeliveries() > 0 && (
                                                <div className='rounded-full w-6 h-6 flex items-center justify-center bg-tibi-primary text-white text-[10px] font-bold'>
                                                    {getNumNewDeliveries()}
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <div
                className={`fixed transition-opacity duration-300 ease-in-out top-0 left-0 w-screen h-screen bg-black z-40 ${overlayClassName}`}
                onClick={() => setOpenSidebarNav(!openSidebarNav)}
            ></div>
        </>
    )
}

export default AsideNaveMobile
