/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { getOrders } from '../../services/api/orders'
import { InlineResponse200, Order, OrderPhase, OrderState } from '../../types'
import DefaultLoader from '../Loaders/DefaultLoader'
import OrderItem from '../OrderItem/OrderItem'
import SearchInput from '../SearchInput/SearchInput'
import Pagination from '../Pagination/Pagination'

export interface SearchOptions {
    page: number
    pageSize: number
    phase?: OrderPhase | undefined
    state?: OrderState | undefined
    searchString?: string | undefined
}

const defaultSearchOptions: SearchOptions = {
    page: 1,
    pageSize: 10,
    phase: OrderPhase.Active,
    state: undefined,
    searchString: ''
}

const OrderOverview: React.FunctionComponent = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const params: any = useParams()
    const previousParams = usePrevious(params)
    const [searchOptions, setSearchOptions] = useState<SearchOptions>({
        ...defaultSearchOptions,
        phase: params.phase ? params.phase : defaultSearchOptions.phase
    })
    const [orders, setOrders] = useState<Order[]>([])
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    // const previousPage = usePrevious(currentPage)

    useEffect(() => {
        setLoading(true)
        let ctrl: AbortController = new AbortController()
        const fetchOrders = async () => {
            try {
                const orderList: InlineResponse200 = await getOrders({ ...searchOptions, page: currentPage })
                setOrders(orderList.items)
                setTotal(orderList.total)
                setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        }
        fetchOrders()
        return () => ctrl.abort()
    }, [currentPage, searchOptions.phase, searchOptions.searchString])

    /** Listen to search params change */
    useEffect(() => {
        setSearchOptions({
            ...searchOptions,
            phase: params.phase ? params.phase : defaultSearchOptions.phase,
            state: OrderState[searchParams.get('state') as keyof typeof OrderState] ?? undefined,
            searchString: searchParams.get('s') || undefined
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    /**
     * Clear searchBar when moving to a new phase tab (and set this new phase)
     */
    useEffect(() => {
        if (previousParams?.phase !== params.phase) {
            setSearchOptions({ ...searchOptions, searchString: undefined, state: undefined, phase: params.phase })
        }
    }, [params.phase])

    const handleSearchChange = (query: string) => {
        let params: { state?: string; s?: string } = {}

        if (query) {
            params.s = query
        }

        if (searchOptions.state) {
            params.state = searchOptions.state
        }
        // @ts-ignore
        setSearchParams(params)
    }

    return (
        <div>
            {/* Searchbar */}
            <div className='flex px-6 pb-3'>
                <SearchInput
                    initialValue={searchOptions.searchString}
                    placeholder='Zoek op opdracht of locatie'
                    onSearchChange={handleSearchChange}
                    className='w-full max-w-lg'
                />
            </div>
            <div>
                {/* LOADER */}
                {loading ? (
                    <div className='w-full flex justify-center py-4'>
                        <DefaultLoader />
                    </div>
                ) : (
                    orders.map((order: Order) => {
                        return <OrderItem key={order.id} data={order} actionButtons={['startThread']} />
                    })
                )}
            </div>
            <Pagination
                page={currentPage}
                totalItems={total}
                onPageChange={(page: any) => {
                    setCurrentPage(page)
                }}
            />
        </div>
    )
}

export default OrderOverview
