import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import IconWithBackground from '../IconWithBackground/IconWithBackground'

interface DashboardNotificationProps {
    icon: IconDefinition
    children?: React.ReactNode
}

const DashboardNotification = ({ icon, children }: DashboardNotificationProps) => {
    return (
        <div className='border shadow-sm border-tibi-fadedPrimary border-opacity-50 bg-white rounded-xl flex items-center gap-x-2 p-3'>
            <IconWithBackground icon={icon} size='small' />
            {children}
        </div>
    )
}

export default DashboardNotification
