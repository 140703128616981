import React, { useEffect, useState } from 'react'
import MessageThread from '../../components/MessageThread/MessageThread'
import TextSectionHeader from '../../components/TextSectionHeader'
import MessagesList from '../../components/MessagesList/MessagesList'
import { useSearchParams } from 'react-router-dom'
import { getMessageThreads } from '../../services/api/messages'
import { OrderMessageThread } from '../../types'

const Messages: React.FunctionComponent = () => {
    const [selectedThread, setSelectedThread] = useState<number | null>(null)
    const [messageThreads, setMessageThreads] = useState<OrderMessageThread[]>([])
    const [searchString, setSearchString] = useState<string>('')
    const [searchParams] = useSearchParams()
    const [width, setWidth] = useState<number>(window.innerWidth)
    const [loading, setLoading] = useState<boolean>(false)

    const isMobile = width <= 1024

    useEffect(() => {
        setLoading(true)
        fetchMessageThreads()

        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        fetchMessageThreads()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString])

    useEffect(() => {
        const selected = searchParams.get('selectedThread')
        if (selected) {
            setSelectedThread(Number(selected))
        } else {
            setSelectedThread(null)
        }
    }, [searchParams])

    const fetchMessageThreads = async () => {
        try {
            const params: any = { pageSize: 1000 }
            if (searchString) {
                params['searchString'] = searchString
            }

            const res = await getMessageThreads(params)

            if (res && res.items) {
                // Filter out messages that have no last message and then sort them by date/time
                const filteredMessages = res.items.filter((message: OrderMessageThread) => message.lastMessage)
                const sortedMessages = sortMessageThreads(filteredMessages)
                setMessageThreads(sortedMessages)
            }
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    const sortMessageThreads = (messages: OrderMessageThread[]) => {
        return messages.sort((a, b) => {
            return new Date(b.lastMessage?.date || 0).getTime() - new Date(a.lastMessage?.date || 0).getTime()
        })
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    return (
        <div className='flex-grow h-full overflow-hidden flex flex-col'>
            <div className='flex shrink-0 flex-grow-0 justify-between mb-4 items-center'>
                <TextSectionHeader>Berichten</TextSectionHeader>
            </div>

            <div className='w-full flex-grow flex flex-col min-h-[500px] max-h-[900px] overflow-hidden rounded-xl'>
                {isMobile ? (
                    <div className='flex-grow flex flex-col overflow-hidden rounded-xl'>
                        {selectedThread ? (
                            <MessageThread threadId={selectedThread} />
                        ) : (
                            <MessagesList
                                messageThreads={messageThreads}
                                selectedThread={selectedThread}
                                setSearchString={setSearchString}
                                loading={loading}
                            />
                        )}
                    </div>
                ) : (
                    <div className='flex flex-grow h-full rounded-xl'>
                        <div className='w-1/3 flex flex-col flex-grow rounded-tl-xl rounded-bl-xl'>
                            <MessagesList
                                messageThreads={messageThreads}
                                selectedThread={selectedThread}
                                setSearchString={setSearchString}
                                loading={loading}
                                className='border-r-0'
                            />
                        </div>

                        <div className='w-2/3 flex flex-col rounded-tr-xl lg:rounded-tl-none rounded-br-xl'>
                            <MessageThread threadId={selectedThread} onSend={fetchMessageThreads} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Messages
