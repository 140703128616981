import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Appointment from '../../components/Appointment/Appointment'
import SearchInput from '../../components/SearchInput/SearchInput'
import TextSectionHeader from '../../components/TextSectionHeader'
import { getAppointments } from '../../services/api/appointments'
import { APPOINTMENT_STATES } from '../../helpers/constants'
import DefaultLoader from '../../components/Loaders/DefaultLoader'
import { OrderAppointment, OrderAppointmentState } from '../../types'
import Pagination from '../../components/Pagination/Pagination'

interface SearchOptions {
    state?: OrderAppointmentState | undefined
    searchString?: string | undefined
    page: number
}

const defaultSearchOptions: SearchOptions = {
    state: undefined,
    searchString: '',
    page: 1
}

const Appointments: React.FunctionComponent = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchOptions, setSearchOptions] = useState<SearchOptions>({
        ...defaultSearchOptions
    })
    const [appointments, setAppointments] = useState<OrderAppointment[]>([])
    const [total, setTotal] = useState<number>(0)

    useEffect(() => {
        const fetchAppointments = async () => {
            setLoading(true)

            try {
                const res = await getAppointments(searchOptions)
                setAppointments(res.items || [])
                setTotal(res.count)
                setLoading(false)
            } catch (e) {
                console.error(e)
                setLoading(false)
            }
        }

        let ctrl: AbortController = new AbortController()
        fetchAppointments()
        return () => ctrl.abort()
    }, [searchOptions])

    /** Listen to search params change */
    useEffect(() => {
        setSearchOptions({
            ...searchOptions,
            state: (searchParams.get('state') as OrderAppointmentState) || undefined,
            searchString: searchParams.get('s') || undefined
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    const fetchAppointments = async () => {
        setLoading(true)

        try {
            const res = await getAppointments(searchOptions)
            setAppointments(res.items || [])
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    const handleSearchChange = (query: string) => {
        let params: { state?: string; s?: string } = { s: query }

        if (searchOptions.state) {
            params.state = searchOptions.state
        }
        // @ts-ignore
        setSearchParams(params)
    }

    const handleStatusChange = (item: any) => {
        let params: { state?: string; s?: string } = { state: item ? item.value : undefined }

        if (searchOptions.searchString) {
            params.s = searchOptions.searchString
        }
        // @ts-ignore
        setSearchParams(params)
    }

    return (
        <div>
            <div className='flex justify-between mb-4 items-center'>
                <TextSectionHeader>Afspraken</TextSectionHeader>
            </div>
            <div className='border border-tibi-fadedPrimary shadow-sm border-opacity-50 bg-white rounded-xl'>
                {/* Searchbar */}
                <div className='flex flex-wrap px-6 pt-6 gap-4'>
                    <SearchInput
                        initialValue={searchOptions.searchString}
                        placeholder='Zoek op opdracht of locatie'
                        onSearchChange={handleSearchChange}
                    />
                    <Select
                        value={APPOINTMENT_STATES.find((s) => s.value === searchOptions.state) || null}
                        onChange={handleStatusChange}
                        options={APPOINTMENT_STATES}
                        placeholder='Alle statussen'
                        className='relative'
                        isClearable
                    />
                </div>
                <div>
                    {loading ? (
                        <div className='w-full flex justify-center py-4'>
                            <DefaultLoader />
                        </div>
                    ) : (
                        <>
                            {total === 0 ? (
                                <div className='text-center text-gray-400 py-8'>Geen afspraken</div>
                            ) : (
                                <div className='pt-4'>
                                    {appointments.map((app: OrderAppointment) => {
                                        return (
                                            <Appointment
                                                key={app.id}
                                                data={app}
                                                fetchAppointments={fetchAppointments}
                                            />
                                        )
                                    })}
                                </div>
                            )}

                            {total > 0 && (
                                <div className='px-6'>
                                    <Pagination
                                        // @ts-ignore
                                        page={searchOptions.page}
                                        totalItems={total}
                                        onPageChange={(page: any) => {
                                            setSearchOptions({
                                                ...searchOptions,
                                                page
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Appointments
