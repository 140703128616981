import React from 'react'
import { faHouse } from '@fortawesome/pro-light-svg-icons'
import IconWithBackground from '../IconWithBackground/IconWithBackground'
import { OrderMessageThread } from '../../types'
import { formatDate, formatTime, isToday, isYesterday } from '../../helpers/utils'
import { NavLink } from 'react-router-dom'

export interface MessageItemProps {
    data: OrderMessageThread
    isSelected?: boolean
}

const MessageItem: React.FunctionComponent<MessageItemProps> = ({ data, isSelected }: MessageItemProps) => {
    const orderPerson = data.concerns

    const getTimeOfLastMessage = () => {
        if (!data.lastMessage?.date) {
            return null
        }

        if (isToday(data.lastMessage.date)) {
            return formatTime(data.lastMessage.date)
        } else if (isYesterday(data.lastMessage.date)) {
            return 'Gisteren'
        } else {
            return formatDate(data.lastMessage.date)
        }
    }

    return (
        <NavLink
            to={`/dashboard/messages?selectedThread=${data.id}`}
            className={`${
                isSelected ? 'bg-tibi-linkColor bg-opacity-5' : 'bg-white'
            } flex w-full hover:bg-gray-50 relative justify-between items-start border-t first:border-t-0 border-tibi-fadedPrimary border-opacity-50 pb-2 pt-6 px-3`}
        >
            <div className='flex flex-shrink-0 justify-center'>
                <IconWithBackground icon={faHouse} />
            </div>

            <div className='flex-grow overflow-hidden'>
                <div className='flex flex-col flex-grow flex-shrink overflow-hidden pl-2 gap-y-1'>
                    <h3 className='font-medium truncate max-w-64 text-sm'>
                        {data.order.address}, {data.order.zipcode} {data.order.city}
                    </h3>
                    <span className='text-tibi-text text-xs'>{data.order.title}</span>
                    <div className='text-xs truncate font-semibold'>
                        <span className='text-tibi-text'>betreffende </span>
                        <span className='text-tibi-linkColor'>{orderPerson}</span>
                    </div>
                    <p className='text-tibi-text text-xs truncate max-w-64 italic'>
                        {data.lastMessage?.message || '<Geen bericht>'}
                    </p>
                </div>

                <div className='absolute right-2 top-0'>
                    <span className='text-[0.7rem] opacity-70'>{data.lastMessage?.date && getTimeOfLastMessage()}</span>
                </div>

                {data.unreadCount !== undefined && data.unreadCount > 0 && (
                    <span className='bg-tibi-primary text-sm w-6 h-6 flex items-center justify-center rounded-full text-white'>
                        {data.unreadCount}
                    </span>
                )}
            </div>
        </NavLink>
    )
}

export default MessageItem
