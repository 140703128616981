import { faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { getFirstCharacters } from '../../helpers/utils'

interface AvatarProps {
    name: string | undefined
    className?: string
}

const Avatar: React.FunctionComponent<AvatarProps> = ({ name, className }: AvatarProps) => {
    return (
        <span
            className={`bg-tibi-mainHeading text-white border border-gray-300 flex items-center justify-center uppercase font-semibold rounded-full w-10 h-10 ${className}`}
        >
            {name ? getFirstCharacters(name) : <FontAwesomeIcon icon={faUser} />}
        </span>
    )
}

export default Avatar
