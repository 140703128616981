import Modal from '../Modal/Modal'

interface ConfirmModalProps {
    title: string
    subtitle?: string
    primaryButtonText: string
    secondaryButtonText?: string
    onRequestClose?: () => void
    onConfirm?: () => void
}

const ConfirmModalContent: React.FunctionComponent<ConfirmModalProps> = ({
    title,
    subtitle,
    primaryButtonText,
    secondaryButtonText,
    onRequestClose,
    onConfirm
}: ConfirmModalProps) => {
    return (
        <Modal
            buttonText={primaryButtonText}
            cancelButtonText={secondaryButtonText}
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
        >
            <div className='p-6'>
                <p className='text-xl font-bold'>{title}</p>
                <p className='pt-4'>{subtitle}</p>
            </div>
        </Modal>
    )
}

export default ConfirmModalContent
