import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './IconWithBackground.scss'
import DynamicFaIcon from '../DynamicFaIcon/DynamicFaIcon'
import { faBoxTaped } from '@fortawesome/pro-light-svg-icons'

interface IconWithBackgroundProps {
    icon?: IconProp
    size?: 'small'
    iconName?: string
    spin?: boolean
}

const IconWithBackground = ({ icon, size, iconName, spin }: IconWithBackgroundProps) => (
    <div
        id='IconWithBackground'
        className={`bg-tibi-blushPrimary border border-tibi-fadedPrimary border-opacity-50 flex-shrink-0 ${
            size === 'small' ? 'w-8 h-8' : 'w-12 h-12'
        } rounded-full flex items-center justify-center text-tibi-primary`}
    >
        {iconName ? (
            <DynamicFaIcon className={`${size === 'small' ? 'text-sm' : 'text-xl'}`} spin={spin} iconName={iconName} />
        ) : (
            <FontAwesomeIcon
                className={`${size === 'small' ? 'text-sm' : 'text-xl'}`}
                icon={icon ? icon : faBoxTaped}
                spin={spin}
            />
        )}
    </div>
)

export default IconWithBackground
