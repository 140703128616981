import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { OrderAppointment } from '../../types'
import AppointmentStateLabel from '../AppointmentStateLabel/AppointmentStateLabel'
import { formatDate, formatTime } from '../../helpers/utils'
import ActionButton, { ActionOption } from '../ActionButton/ActionButton'
import { rejectAppointment } from '../../services/api/appointments'

interface AppointmentProps {
    data: OrderAppointment
    fetchAppointments: () => void
}

const Appointment: React.FunctionComponent<AppointmentProps> = ({ data, fetchAppointments }: AppointmentProps) => {
    // const handleConfirmAppointment = async () => {
    //     try {
    //         await confirmAppointment(data.id)
    //         fetchAppointments()
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    const handleRejectAppointment = async () => {
        try {
            await rejectAppointment(data.id)
            fetchAppointments()
        } catch (e) {
            console.error(e)
        }
    }

    const options: ActionOption[] = []
    if (data.state !== 'cancelled') {
        options.push({ text: 'annuleren', type: 'danger', action: handleRejectAppointment })
    }

    return (
        <div className='flex flex-wrap relative items-center justify-between pl-6 pr-4 py-3 border-b border-tibi-fadedPrimary border-opacity-50 w-full gap-y-2'>
            <div className='flex items-start space-x-3 w-full md:w-5/12'>
                {/* DATE */}
                <FontAwesomeIcon className='text-md text-tibi-text mt-1' icon={faCalendar} />
                <div className='flex flex-col'>
                    <span className='font-semibold text-sm'>
                        {data.order?.address}, {data.order?.zipcode} {data.order?.city}
                    </span>
                    <span className='text-xs text-tibi-textLight'>{`${data.order?.title}`}</span>
                    <span className='text-xs text-tibi-textLight'>
                        {data.date && formatDate(data.date)} - {data.date && formatTime(data.date)}
                    </span>
                </div>
            </div>
            <div className='w-5/12 md:w-4/12 pl-6 md:pl-0'>
                {/* WHO */}
                <span className='text-sm text-tibi-linkColor font-medium'>{data.concerns}</span>
            </div>
            <div className='w-5/12 md:w-2/12'>
                <AppointmentStateLabel state={data.state} />
            </div>
            <div className='w-2/12 md:w-1/12 flex justify-end'>
                <ActionButton options={options} />
            </div>
        </div>
    )
}

export default Appointment
