import { faBoxArchive } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import React from 'react'

const OrderTabs: React.FunctionComponent = () => {
    const options = [
        {
            label: 'In behandeling',
            route: ''
        },
        {
            label: 'Opgeleverd',
            route: 'completed'
        },
        {
            label: 'Archief',
            route: 'archived',
            icon: faBoxArchive
        }
    ]

    return (
        <div className='flex px-5 text-lg border-b overflow-x-auto w-full gap-x-6'>
            {options.map((option: any, optionIndex: number) => {
                return (
                    <NavLink
                        key={optionIndex}
                        to={option.route}
                        end={option.route === ''}
                        className={({ isActive }) =>
                            `pt-4 pb-2 last:ml-auto text-base font-semibold border-b-2 min-w-fit ${
                                isActive ? 'text-black border-tibi-primary' : 'border-transparent text-gray-600'
                            }`
                        }
                    >
                        {option.icon && <FontAwesomeIcon icon={option.icon} className='mr-2' />}
                        <span className='w-full'>{option.label}</span>
                    </NavLink>
                )
            })}
        </div>
    )
}

export default OrderTabs
