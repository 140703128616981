import CategoryHeader from '../CategoryHeader'
import PackageItem from '../PackageItem/PackageItem'

interface Props {
    packages: any[]
    selectedProducts: any[]
    onSelectionChange: any
    addProductToBasket: (addedProduct: any, isPack: boolean) => void
    removeProductFromBasket: (product: any) => void
}

function PopularPackages(props: Props) {
    const packages: any[] = props.packages

    return (
        <>
            <CategoryHeader title='Populaire pakketten' />
            <ul className='flex border divide-x mt-4 divide-tibi-fadedPrimary divide-opacity-50 border-tibi-fadedPrimary shadow-sm border-opacity-50 bg-white rounded-xl'>
                {packages?.map((a) => {
                    const inBasket = props.selectedProducts.find((curr) => curr._isPack === true && curr.id === a.id)

                    return (
                        <PackageItem
                            a={a}
                            layout='block'
                            key={`package-${a.id}`}
                            inBasket={inBasket}
                            addProductToBasket={props.addProductToBasket}
                            removeProductFromBasket={props.removeProductFromBasket}
                        />
                    )
                })}
            </ul>
        </>
    )
}

export default PopularPackages
