import IconWithBackground from '../../components/IconWithBackground/IconWithBackground'
import {
    faBox,
    faCheck,
    faDownload,
    faFlagCheckered,
    faHouse,
    faSpinner,
    faTimes
} from '@fortawesome/pro-light-svg-icons'
import { formatDate } from '../../helpers/utils'
import { OrderDelivery } from '../../types'
import { getOrderDeliveryDownloadLink } from '../../services/api/deliveries'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeliveries } from '../../hooks/useDeliveries'

interface DeliveryItemProps {
    data: OrderDelivery
    onDownload(): void
}

const DeliveryItem = ({ data, onDownload }: DeliveryItemProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const { getDeliveries } = useDeliveries()

    const orderDate = formatDate(data.order?.orderDate, { year: 'numeric', month: 'long', day: '2-digit' })
    const deliveryDate = formatDate(data.deliveryDate, { year: 'numeric', month: 'long', day: '2-digit' })

    const handleDownload = async () => {
        if (!data.id) return

        setLoading(true)

        try {
            const res = await getOrderDeliveryDownloadLink(data.id)
            const downloadLink = res?.downloadUrl
            window.open(downloadLink, '_blank')
            await getDeliveries()
            onDownload()
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <div
            className={`flex flex-wrap relative items-center justify-between px-4 py-3 border-b border-tibi-fadedPrimary border-opacity-50 w-full gap-y-2 ${
                !data.downloaded && 'newDelivery'
            }`}
        >
            <div className='flex items-center gap-x-2 w-full md:w-1/2 lg:w-1/3 relative z-10'>
                <IconWithBackground icon={faBox} />

                <div className='flex flex-col'>
                    <span className={`font-medium text-tibi-text`}>{data.order?.title}</span>

                    <span className='text-xs text-tibi-textLight'>
                        <strong>Besteld</strong>: {orderDate}
                    </span>
                </div>
            </div>

            <div className='flex items-center gap-x-2 w-full md:w-1/2 lg:w-1/4'>
                <IconWithBackground icon={faHouse} size='small' />
                <div className='flex flex-col text-sm text-tibi-text'>
                    <span>
                        {data.order?.address?.street} {data.order?.address?.houseNumber}
                        {data.order?.address?.extension}
                    </span>
                    <span>
                        {data.order?.address?.zipcode}, {data.order?.address?.city}
                    </span>
                </div>
            </div>

            <div className='flex flex-col gap-y-1 text-xs text-tibi-text w-full md:w-1/2 lg:w-1/4'>
                <div className='flex items-center'>
                    <FontAwesomeIcon className={'mr-1.5'} icon={faFlagCheckered} />
                    <span className='font-medium text-tibi-text'>Opgeleverd: {deliveryDate}</span>
                </div>
                <div className='flex items-center'>
                    <span className={`${!data.downloaded && 'font-bold italic'}`}>
                        <FontAwesomeIcon
                            className={'mr-1.5 ' + (data.downloaded ? 'text-green-700' : 'text-red-600')}
                            icon={data.downloaded ? faCheck : faTimes}
                        />
                        Bestanden {!data.downloaded && 'nog niet'} gedownload
                    </span>
                </div>
            </div>

            <button onClick={handleDownload} disabled={loading} className={loading ? 'cursor-not-allowed' : ''}>
                <IconWithBackground icon={loading ? faSpinner : faDownload} spin={loading} size='small' />
            </button>
        </div>
    )
}

export default DeliveryItem
