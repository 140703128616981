import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Tooltip } from 'react-tooltip'
import { OrderAppointment } from '../../types'

interface AppointmentIndicatorProps {
    appointments: OrderAppointment[] | undefined
}

const AppointmentIndicator: React.FunctionComponent<AppointmentIndicatorProps> = (props: AppointmentIndicatorProps) => {
    const { appointments } = props

    if (!appointments || appointments.length <= 0) {
        return <div className='text-center text-gray-500 text-xs'>Nog geen afspraken</div>
    }

    const upcomingApp = appointments[0]

    const dateIsUpcoming = (date: string | undefined) => {
        if (!date) {
            return false
        }
        const parsedDate = Date.parse(date)
        const currentDate = Date.parse(new Date().toISOString())
        return parsedDate > currentDate
    }

    return (
        <a href='/dashboard/appointments'>
            <div
                className='flex space-x-1.5 text-tibi-text font-semibold items-center justify-center'
                id={`appointmentInfo-${upcomingApp.id}`}
            >
                <FontAwesomeIcon icon={faCalendar} />
                <span className='underline hover:no-underline'>{appointments.length}</span>
                {dateIsUpcoming(upcomingApp.date) && (
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{ fontSize: '0.45rem' }}
                        className='text-tibi-primary rounded-full bg-tibi-primary -mt-2'
                    />
                )}
            </div>
            <Tooltip
                anchorSelect={`#appointmentInfo-${upcomingApp.id}`}
                variant='light'
                className='bg-white bg-opacity-100 p-0 m-0 rounded-lg shadow-lg z-50'
            >
                <div className='flex flex-col items-center gap-y-0.5 py-1 px-1'>
                    <span className='text-xs text-tibi-textLight'>
                        {dateIsUpcoming(upcomingApp.date) ? 'eerstvolgende afspraak' : 'meest recente afspraak'}:
                    </span>
                    {upcomingApp.date && (
                        <h4 className='text-tibi-mainHeading text-sm font-semibold'>
                            {new Intl.DateTimeFormat('nl-NL', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            }).format(new Date(upcomingApp.date))}
                        </h4>
                    )}
                    <span className='text-tibi-linkColor font-semibold text-xs'>{upcomingApp.concerns}</span>
                </div>
            </Tooltip>
        </a>
    )
}

export default AppointmentIndicator
