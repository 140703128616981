import { faBell } from '@fortawesome/pro-light-svg-icons'
import CategoryHeader from '../CategoryHeader'
// import Notificatie from '../Notification/Notification'

export interface NotificatiesProps {}

function Notificaties() {
    return (
        <div className='space-y-4 flex-1 h-full flex flex-col'>
            <CategoryHeader title='Notificaties' icon={faBell} />
            <div className='flex shadow-sm flex-col bg-white border border-tibi-fadedPrimary border-opacity-50 rounded-xl flex-1'>
                {/* <Notificatie title='Hello World' content='This is the content of a notification' />
                <Notificatie title='Hello World' content='This is the content of a notification' />
                <Notificatie title='Hello World' content='This is the content of a notification' /> */}
                <div className='text-center text-gray-400 py-8 text-sm'>Geen notificaties</div>
            </div>
        </div>
    )
}

export default Notificaties
